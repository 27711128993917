import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import { Disclosure, Popover } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { useRef } from "react";
import accreditations from "../assets/accreditations.json";
import BgImage from "./BgImage";

type AccKey = keyof typeof accreditations;
const accs = (Object.keys(accreditations) as AccKey[]).sort().map((k) => ({
  id: k,
  ...accreditations[k],
}));
type Accreditation = (typeof accs)[number];

const Item: React.FC<{ data: Accreditation }> = ({ data }) => {
  const arrowRef = useRef(null);
  const { refs, floatingStyles, middlewareData } = useFloating({
    placement: "top",
    middleware: [
      offset(15),
      flip(),
      shift({ padding: 20 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });
  return (
    <Popover as="li" ref={refs.setReference} className="mx-auto w-full">
      <Popover.Button className="w-full cursor-help">
        <BgImage
          className={clsx(
            "aspect-video !bg-cover mix-blend-multiply grayscale hover:grayscale-0",
          )}
          src={data.image}
        >
          <span className="sr-only">{data.title}</span>
        </BgImage>
      </Popover.Button>
      <Popover.Panel
        className="max-w-xs text-xs leading-relaxed"
        ref={refs.setFloating}
        style={floatingStyles}
      >
        <svg
          ref={arrowRef}
          style={{
            left: `${middlewareData.arrow?.x || 0}px`,
            bottom: `${(middlewareData.arrow?.y || 0) - 10}px`,
          }}
          viewBox="0 0 100 70"
          className="absolute h-4 w-4 rotate-180 fill-black/90"
        >
          <path d="m50 0 50 70H0z" />
        </svg>
        <div className="rounded bg-black/90 p-4 text-white shadow-lg">
          <h4 className="font-bold">{data.title}</h4>
          <p>{data.text}</p>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

const FooterAccreditations: React.FC = () => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="container relative grid place-items-start">
            <Disclosure.Button className="-ml-4 -mt-9 flex items-center gap-2 rounded-t-lg bg-[#ABB1B7] px-4 py-2 text-sm font-semibold text-white">
              <span>Accreditations</span>
              <ChevronRightIcon
                className={clsx(
                  "h-3 w-3 transform transition",
                  open && "rotate-90",
                )}
                strokeWidth={3}
              />
            </Disclosure.Button>
          </div>
          <Disclosure.Panel className="border-t bg-gray-100">
            <ul
              className={clsx(
                "container grid w-full gap-10 py-4",
                "grid-cols-3 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-9",
              )}
            >
              {accs
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((d) => (
                  <Item key={d.id} data={d} />
                ))}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default FooterAccreditations;
