import { Popover } from "@headlessui/react";
import { routes, t } from "@prmf/site-router";
import { clsx } from "clsx";
import MenuPopover from "./MenuPopover";
import SmartLink from "./SmartLink";

type Props = {
  data: { path: string; items?: string[] }[];
  className?: string;
};

export default function Menu(props: Props) {
  const itemStyle = clsx(
    "whitespace-nowrap p-6 text-xs font-medium uppercase leading-snug",
  );
  return (
    <Popover.Group
      as="ul"
      className={clsx("flex flex-1 justify-between", props.className)}
    >
      {props.data.map((d) =>
        d.items?.length ? (
          <MenuPopover
            key={d.path}
            path={d.path}
            items={d.items}
            linkClassName={itemStyle}
          />
        ) : (
          <li key={d.path} className="flex items-center">
            <SmartLink
              to={d.path}
              className={clsx(
                itemStyle,
                d.path === routes.extraParts && "bg-gray-400 text-white",
              )}
            >
              {t(d.path, "title")}
            </SmartLink>
          </li>
        ),
      )}
    </Popover.Group>
  );
}
