import { routes, t } from "@prmf/site-router";
import { clsx } from "clsx";
import BgImage from "./BgImage";
import Markdown from "./Markdown";
import SmartLink from "./SmartLink";

type Props = {
  className?: string;
};

const links = [
  {
    path: routes.products,
    items: [
      routes.kitchens,
      routes.appliances,
      routes.handles,
      routes.sinks,
      routes.cabinets,
      routes.taps,
      routes.worktops,
      routes.shelving,
      routes.laminates,
      routes.splashbacks,
    ],
  },
  {
    path: routes.about,
    items: [
      routes.history,
      routes.mission,
      routes.accreditations,
      routes.innovation,
      routes.sustainability,
      routes.corporateResponsibility,
    ],
  },
  {
    path: routes.marketSectors,
    items: [
      routes.privateDevelopers,
      routes.socialHousing,
      routes.bespokeSolutions,
    ],
  },
  {
    path: "Careers",
    items: [routes.workingWithUs, routes.careers, routes.ourTeam],
  },
  {
    path: "News and Tools",
    items: [
      routes.visualiser,
      routes.newsCaseStudies,
      routes.brochures,
      routes.news,
      routes.terms,
    ],
  },
];

const social = [
  { link: routes.instagram, image: "/media/assets/logo-instagram.svg" },
  { link: routes.linkedIn, image: "/media/assets/logo-linkedin.svg" },
  { link: routes.trustpilot, image: "/media/assets/logo-trustpilot.svg" },
];

const FooterNav: React.FC<Props> = (props) => {
  return (
    <footer className={clsx(props.className)}>
      <nav className="hidden bg-[#92989E] text-xs text-neutral-200 lg:block">
        <ul
          className={clsx(
            "container grid grid-flow-col justify-between",
            "divide-x divide-[#ABB1B7] py-4",
          )}
        >
          {links.map((s) => (
            <li key={s.path} className="w-full pl-4 first:pl-0 xl:pl-8">
              <h4 className="mb-2 text-xs uppercase underline">
                {t(s.path, "title", false) || s.path}
              </h4>
              <ul
                className={clsx(
                  "grid w-full gap-1",
                  [routes.products, routes.about].includes(s.path) &&
                    "grid-cols-2 xl:gap-x-10",
                )}
              >
                {s.items.map((l) => (
                  <li key={l}>
                    <SmartLink to={l} className="hover:text-white">
                      {t(l, "title")}
                    </SmartLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
      <div className="bg-black p-2 text-xs text-neutral-400">
        <div
          className={clsx(
            "container grid gap-6",
            "place-items-center",
            "max-lg:text-center lg:grid-flow-col",
          )}
        >
          <BgImage
            src="/media/assets/logo-craftsmanship.svg"
            className="mt-4 aspect-square !w-16 bg-transparent lg:hidden"
          />
          <div className="flex gap-6 lg:order-last">
            {social.map((l) => (
              <a
                key={l.link}
                href={l.link}
                rel="noopener noreferer"
                target="_blank"
                className={clsx(
                  "h-4 w-4 bg-contain bg-center transition",
                  "opacity-60 hover:opacity-90",
                )}
                style={{ backgroundImage: `url(${l.image})` }}
              />
            ))}
          </div>
          <Markdown
            className={clsx(
              "place-self-start",
              "space-y-0 text-[11px] leading-relaxed",
              "max-lg:[&_p]:inline",
              "max-lg:[&_p:nth-child(2)]:block",
              "max-lg:[&_p:nth-child(2)]:pb-3",
              "lg:[&_p:nth-child(1)]:inline",
              "lg:[&_p:nth-child(1)]:after:content-['_|']",
              "lg:[&_p:nth-child(2)]:inline",
              "lg:[&_p]:!m-0",
              "[&_a:hover]:text-gray-200",
            )}
          >
            {`
              ©${new Date().getFullYear()} Paula Rosa Manhattan

              tel [+44 (0)1903 524 300](tel:+441903524300) |
              email  [info@prmf.co.uk](mailto:info@prmf.co.uk)

              Registered Office: Blenheim Road | Lancing Business Park | Lancing
              | West Sussex | BN15 8UH | Registered in England No. 0460938.

              [Terms, Conditions and Policies](${routes.terms})
              website by [bakerwilcox.com](https://bakerwilcox.com)
              `}
          </Markdown>
        </div>
      </div>
    </footer>
  );
};

export default FooterNav;
