import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import { Popover, Transition } from "@headlessui/react";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  NewspaperIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { routes, t } from "@prmf/site-router";
import { NavLink } from "@remix-run/react";
import { clsx } from "clsx";
import { Fragment, useEffect, useRef, useState } from "react";
import MenuImage from "./MenuImage";

type Props = { path: string; items: string[]; linkClassName?: string };

const MenuPopover: React.FC<Props> = (props) => {
  const { path, items } = props;
  const [hoverPath, setHoverPath] = useState<string>();
  const arrowRef = useRef(null);
  const { refs, floatingStyles, middlewareData } = useFloating({
    middleware: [
      offset(15),
      flip(),
      shift({ padding: 10 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });
  return (
    <Popover as="li" ref={refs.setReference} className="relative z-40 flex">
      {({ open }) => {
        useEffect(() => {
          if (!open) setHoverPath(undefined);
        }, [open]);
        return (
          <>
            <Popover.Button
              className={clsx(
                "relative",
                "focus:outline focus:outline-0",
                props.linkClassName,
              )}
            >
              <Transition
                show={open}
                enter="transition ease-out duration-200 origin-left"
                leave="transition ease-in duration-150 origin-right"
                enterFrom="opacity-0 scale-x-0"
                leaveTo="opacity-0 scale-x-0"
                className="bg-accent absolute left-0 top-0 h-1 w-full"
              />

              {t(path, "title")}
            </Popover.Button>
            <Transition as={Fragment}>
              <Popover.Panel
                ref={refs.setFloating}
                style={floatingStyles}
                className="w-screen max-w-md md:max-w-lg lg:max-w-4xl"
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  leave="transition ease-in duration-150"
                  enterFrom="opacity-0 -translate-x-10"
                  leaveTo="opacity-0 translate-x-10"
                >
                  <svg
                    ref={arrowRef}
                    style={{
                      left: `${middlewareData.arrow?.x || 0}px`,
                      top: `${middlewareData.arrow?.y || 0}px`,
                    }}
                    className="absolute -mt-2 h-3 w-4 fill-white"
                    viewBox="0 0 100 70"
                  >
                    <path d="m50 0 50 70H0z" />
                  </svg>
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  leave="transition ease-in duration-150"
                  enterFrom="translate-y-2 opacity-0"
                  leaveTo="_translate-y-2 opacity-0"
                >
                  <div
                    className={clsx(
                      "overflow-hidden rounded-lg shadow-lg",
                      "ring-1 ring-black ring-opacity-5",
                      "transition",
                    )}
                  >
                    <div className="bg-white p-7">
                      <h3
                        className={clsx(
                          "mb-4 border-b pb-2 text-sm font-bold",
                          "uppercase leading-snug text-black",
                        )}
                      >
                        {t(path, "title")}
                      </h3>
                      <div className="flex gap-4">
                        <div className="flex-1">
                          <div
                            className={clsx(
                              "grid gap-4",
                              items.length > 3 && "sm:grid-cols-2",
                            )}
                          >
                            {items.map((i) => (
                              <Popover.Button
                                as={NavLink}
                                to={i}
                                key={i}
                                className="group outline outline-0"
                                onMouseEnter={() => setHoverPath(i)}
                              >
                                <h2
                                  className={clsx(
                                    "table text-sm font-bold capitalize",
                                    "leading-snug text-neutral-700",
                                    "[.active_&]:bg-accent",
                                    "group-hover:bg-accent",
                                    "group-focus:bg-accent",
                                  )}
                                >
                                  {t(i, "title")}
                                </h2>
                                <p className="leading-tight text-neutral-400">
                                  <small>{t(i, "description")}</small>
                                </p>
                              </Popover.Button>
                            ))}
                          </div>
                          <Transition.Child
                            enter="transition ease-out delay-100 duration-200"
                            leave="transition ease-in delay-75 duration-150"
                            enterFrom="translate-y-2 opacity-0"
                            leaveTo="translate-y-2 opacity-0"
                            className={clsx(
                              "-ml-3",
                              "mt-4 grid grid-rows-2 divide-y divide-white",
                              "rounded-md bg-zinc-100 text-sm",
                            )}
                          >
                            <div className="grid grid-cols-2 divide-x divide-white">
                              <Popover.Button
                                as={NavLink}
                                to={routes.contact}
                                className="group flex items-center gap-2 px-3 py-2 outline outline-0"
                              >
                                <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                                <span className="group-hover:bg-accent group-focus:bg-accent">
                                  Contact Us
                                </span>
                              </Popover.Button>
                              <Popover.Button
                                as={NavLink}
                                to={routes.customerReviews}
                                className="group flex items-center gap-2 px-3 py-2 outline outline-0"
                              >
                                <StarIcon className="h-4 w-4" />
                                <span className="group-hover:bg-accent group-focus:bg-accent">
                                  Latest Customer Reviews
                                </span>
                              </Popover.Button>
                            </div>
                            <Popover.Button
                              as={NavLink}
                              to={routes.newsCompany}
                              className="group flex items-center gap-2 px-3 py-2 outline outline-0"
                            >
                              <NewspaperIcon className="h-4 w-4" />

                              <span className="group-hover:bg-accent group-focus:bg-accent">
                                Read our latest company updates and news
                              </span>
                            </Popover.Button>
                          </Transition.Child>
                        </div>
                        <Transition.Child
                          enter="transition ease-out delay-100 duration-200"
                          leave="transition ease-in delay-75 duration-150"
                          enterFrom="translate-x-2 opacity-0"
                          leaveTo="translate-x-2 opacity-0"
                          className={clsx(
                            "relative overflow-hidden",
                            "hidden w-full max-w-[350px] rounded-md",
                            "bg-neutral-100 bg-cover lg:block",
                          )}
                        >
                          <MenuImage path={hoverPath} items={items} />
                        </Transition.Child>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

export default MenuPopover;
