import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { t } from "@prmf/site-router";
import { clsx } from "clsx";
import { Fragment, useRef, useState } from "react";
import SmartLink from "./SmartLink";
import { twMerge } from "tailwind-merge";

type Props = {
  data: { path: string; items?: string[] }[];
  className?: string;
};

type ListProps = {
  parent?: string;
  items?: string[];
  onBack?: () => void;
  onClick?: (path: string) => boolean | undefined;
};

const List: React.FC<ListProps> = (props) => {
  return (
    <>
      {props.parent && (
        <li className="border-b font-semibold">
          <button
            onClick={props.onBack}
            className="-ml-4 flex items-center pb-1"
          >
            <ChevronLeftIcon className="h-4 w-4" />
            {t(props.parent, "title")}
          </button>
        </li>
      )}
      {props.items?.map((d) => (
        <li key={d}>
          <Popover.Button
            as={SmartLink}
            to={d}
            className="[&.active]:bg-accent p-1"
            onClick={(e) => {
              if (props.onClick?.call(null, d)) {
                e.preventDefault();
                return;
              }
            }}
            children={t(d, "title")}
          />
        </li>
      ))}
    </>
  );
};

export default function MenuMobile(props: Props) {
  const [key, setKey] = useState<string>();
  const arrowRef = useRef(null);
  const { refs, floatingStyles, middlewareData } = useFloating({
    placement: "bottom-end",
    middleware: [
      offset(30),
      flip(),
      shift({ padding: 0 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const parents = props.data.filter((d) => d.items).map((d) => d.path);
  const items = key ? props.data.find((d) => d.path === key)?.items : undefined;

  return (
    <Popover
      ref={refs.setReference}
      className={twMerge("relative ml-auto mr-4", props.className)}
    >
      <Popover.Button className="relative h-6 w-6">
        {({ open }) => (
          <>
            <Transition
              show={open}
              enter="transition ease-in"
              leave="transition ease-out"
              enterFrom="-rotate-45 opacity-0"
              leaveTo="rotate-45 opacity-0"
              as={XMarkIcon}
              className="absolute top-0 h-6 w-6"
            />
            <Transition
              show={!open}
              enter="transition ease-in"
              leave="transition ease-out"
              enterFrom="-rotate-45 opacity-0"
              leaveTo="rotate-45 opacity-0"
              as={Bars3Icon}
              className="absolute top-0 h-6 w-6"
            />
          </>
        )}
      </Popover.Button>
      <Popover.Overlay className="fixed inset-0 bg-black/20" />
      <Transition as={Fragment}>
        <Popover.Panel
          ref={refs.setFloating}
          style={floatingStyles}
          className="w-screen max-w-xs text-black"
        >
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-200"
            leave="transition ease-in duration-150"
            enterFrom="opacity-0 translate-y-2"
            leaveTo="opacity-0 translate-y-2"
          >
            <svg
              ref={arrowRef}
              style={{
                left: `${middlewareData.arrow?.x || 0}px`,
                top: `${middlewareData.arrow?.y || 0}px`,
              }}
              className="absolute -mt-2 h-3 w-4 fill-white"
              viewBox="0 0 100 70"
            >
              <path d="m50 0 50 70H0z" />
            </svg>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-200"
            leave="transition ease-in duration-150"
            enterFrom="translate-y-2 opacity-0"
            leaveTo="translate-y-2 opacity-0"
          >
            <div
              className={clsx(
                "overflow-hidden rounded-lg shadow-lg",
                "ring-1 ring-black ring-opacity-5",
                "transition",
                "text-sm",
              )}
            >
              <div className="bg-white p-8">
                <Transition
                  as="ul"
                  className="grid grid-cols-1 gap-4"
                  show={!key}
                  enter="absolute transition ease-in"
                  leave="absolute transition ease-out"
                  enterFrom="opacity-0 translate-x-2"
                  leaveTo="opacity-0 translate-x-2"
                >
                  <List
                    items={props.data.map((d) => d.path)}
                    onClick={(p) => {
                      if (parents.includes(p)) {
                        setKey(p);
                        return true;
                      }
                    }}
                  />
                </Transition>
                <Transition
                  as="ul"
                  className="grid grid-cols-1 gap-4"
                  show={Boolean(key)}
                  enter="absolute transition ease-in"
                  leave="absolute transition ease-out"
                  enterFrom="opacity-0 translate-x-2"
                  leaveTo="opacity-0 translate-x-2"
                >
                  <List
                    parent={key}
                    items={items}
                    onBack={() => setKey(undefined)}
                  />
                </Transition>
              </div>
            </div>
          </Transition.Child>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
