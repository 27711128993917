import {
  AuthBar,
  FooterAccreditations,
  FooterNav,
  GA,
  Menu,
} from "@prmf/site-components";
import { routes, t } from "@prmf/site-router";
import type {
  HeadersFunction,
  LinksFunction,
  LoaderFunctionArgs,
} from "@remix-run/node";
import {
  Links,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  redirect,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { Toaster } from "react-hot-toast";
import { session } from "./cookies.server";
import "./index.css";

export const headers: HeadersFunction = () => ({
  //https://firebase.google.com/docs/hosting/manage-cache
  //"Cache-Control": "public, max-age=86400, s-max-age=86400",
});

export const meta: MetaFunction = ({ location }) => {
  const origin = import.meta.env.VITE_SITE_ORIGIN;
  //const url = new URL(location.pathname, origin);
  const path = location.pathname;
  const title = `${t(path, "title", false) || "Welcome"} – Paula Rosa Manhattan`;
  const description = t(path, "description", false);
  const image = new URL(t(path, "image", false) || "", origin);
  return [
    { title },
    { name: "description", content: description },
    { name: "og:title", content: title },
    { name: "og:description", content: description },
    { name: "og:image", content: image },
    { property: "name", content: title },
    { property: "description", content: description },
    { property: "image", content: image },
  ];
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap",
  },
  { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png",
  },
  { rel: "manifest", href: "/site.webmanifest" },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const ctParam = url.searchParams.get("type");
  if (ctParam && ["private", "social", "bespoke", "retail"].includes(ctParam)) {
    const expires = new Date();
    expires.setUTCMonth(expires.getUTCMonth() + 1);
    if (ctParam === "retail") {
      return redirect(routes.retailCustomers, 301);
    }
    return redirect(url.pathname, {
      headers: {
        "Set-Cookie": await session.serialize(
          { customerType: ctParam },
          { expires },
        ),
      },
    });
  }
  const ct = (await session.parse(request.headers.get("Cookie")))?.customerType;
  return json({ customerType: ct });
};

export default function App() {
  const data = useLoaderData<typeof loader>();
  const { state } = useNavigation();

  return (
    <html lang="en" className={data.customerType}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <GA />
        <Toaster containerClassName="text-sm" />
        <ScrollRestoration />
        {state !== "idle" && (
          <div className="bg-accent fixed inset-x-0 top-0 h-1 animate-pulse" />
        )}
        <header>
          <AuthBar />
          <Menu />
        </header>
        <main>
          <Outlet />
        </main>
        <div className="mt-auto">
          <FooterAccreditations />
          <FooterNav />
        </div>
        <div className="mt-auto"></div>
        <Scripts />
      </body>
    </html>
  );
}
