import { routes } from "@prmf/site-router";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import { twMerge } from "tailwind-merge";

const menuData = [
  {
    path: routes.products,
    items: [
      routes.kitchens,
      routes.appliances,
      routes.handles,
      routes.sinks,
      routes.cabinets,
      routes.taps,
      routes.worktops,
      routes.shelving,
      routes.laminates,
      routes.splashbacks,
    ],
  },
  {
    path: routes.about,
    items: [
      routes.history,
      routes.sustainability,
      routes.innovation,
      routes.accreditations,
      routes.mission,
      routes.corporateResponsibility,
      routes.workingWithUs,
      routes.ourTeam,
    ],
  },
  { path: routes.visualiser },
  { path: routes.sustainability },
  { path: routes.newsCaseStudies },
  {
    path: routes.news,
    items: [
      routes.newsLatestTrends,
      routes.newsSustainability,
      routes.newsCaseStudies,
      routes.newsCompany,
    ],
  },
  {
    path: routes.marketSectors,
    items: [
      routes.privateDevelopers,
      routes.socialHousing,
      routes.bespokeSolutions,
      routes.retailCustomers,
    ],
  },
  { path: routes.brochures },
  { path: routes.extraParts },
];

export default function Menu() {
  return (
    <nav className="container relative flex items-center gap-20">
      <MenuDesktop data={menuData} className="hidden xl:flex" />
      <MenuMobile
        data={[
          ...menuData,
          { path: routes.customerReviews },
          { path: routes.contact },
        ]}
        className={twMerge(
          "fixed right-0 top-6 z-50",
          "grid h-8 w-8 place-items-center",
          "rounded-full bg-[#292929]/50 text-white shadow-lg xl:hidden",
        )}
      />
    </nav>
  );
}
