import { Popover, Transition } from "@headlessui/react";
import { AppLoaderData, routes, t } from "@prmf/site-router";
import { Form, NavLink, useRouteLoaderData, useSubmit } from "@remix-run/react";
import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import BgImage from "./BgImage";

const paths = {
  [routes.privateDevelopers]: "private",
  [routes.socialHousing]: "social",
  [routes.bespokeSolutions]: "bespoke",
  [routes.retailCustomers]: "retail",
};

export default function AuthBar() {
  const { customerType } = useRouteLoaderData("root") as AppLoaderData;
  const submit = useSubmit();
  return (
    <div className="bg-[#292929] text-xs text-white">
      <div className="container relative flex h-8 justify-between gap-6 max-xl:hidden">
        <Popover className="">
          <Popover.Button className="py-2 outline-none">
            Hello, customer ({t(customerType, "title", false) || "Unknown"})
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-in"
            leave="transition ease-ou"
            enterFrom="opacity-0"
            leaveTo="opacity-0"
            {...(customerType ? {} : { show: true })}
          >
            <Popover.Panel
              {...(customerType ? {} : { static: true })}
              className="fixed inset-0 z-50 grid place-items-center"
            >
              <Popover.Overlay
                className={twMerge(
                  "absolute inset-0",
                  customerType ? "bg-black/40" : "bg-black/70",
                )}
              />
              <Transition.Child
                as={Fragment}
                enter="transition ease-in"
                leave="transition ease-ou"
                enterFrom="-translate-y-10"
                leaveTo="translate-y-10"
              >
                <div className="relative m-4 max-w-3xl rounded bg-white p-4 text-black">
                  <h3 className="text-center text-lg font-semibold">
                    Choose your interest
                  </h3>
                  <h4 className="text-center text-lg font-light">
                    You can always adjust this later
                  </h4>
                  <Form
                    className="grid items-start gap-6 py-8 md:grid-cols-4 md:gap-8"
                    onChange={(e) =>
                      submit(e.currentTarget, { action: location.pathname })
                    }
                  >
                    {[
                      routes.privateDevelopers,
                      routes.socialHousing,
                      routes.bespokeSolutions,
                      routes.retailCustomers,
                    ].map((r) => (
                      <Popover.Button
                        as="label"
                        className="cursor-pointer text-left"
                        key={r}
                      >
                        <BgImage
                          src={t(r, "image")}
                          className="relative hidden aspect-square overflow-hidden rounded md:block"
                        />
                        <div className="my-2 md:max-w-[200px]">
                          <h4 className="text-xs font-bold">{t(r, "title")}</h4>
                          <p className="text-xs">{t(r, "description")}</p>
                        </div>
                        <input
                          type="radio"
                          name="type"
                          value={paths[r]}
                          className="sr-only"
                        />
                      </Popover.Button>
                    ))}
                  </Form>
                </div>
              </Transition.Child>
            </Popover.Panel>
          </Transition>
        </Popover>
        <NavLink to={routes.contact} className="py-2 [&.active]:font-bold">
          Contact us
        </NavLink>
      </div>
      <NavLink
        to={routes.home}
        className="container ml-0 block w-full max-w-[400px] py-2 xl:mx-auto xl:-mt-8"
      >
        <BgImage
          className={twMerge(
            "h-[70px] xl:h-[85px]",
            "bg-transparent bg-contain bg-center",
          )}
          src="/media/logo.svg"
        />
      </NavLink>
    </div>
  );
}
