import { useLocation } from "@remix-run/react";
import { useEffect } from "react";

let prev: string;

declare global {
  interface Window {
    gtag(...args: any): void;
  }
}

export default function GA() {
  const { pathname } = useLocation();
  const gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID;

  useEffect(() => {
    if (!prev) return void (prev = pathname);
    if (prev === pathname) return;
    prev = pathname;
    window.gtag("event", "page_view");
  }, [pathname]);

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}');
              `,
        }}
      />
    </>
  );
}
