import { Transition } from "@headlessui/react";
import { t } from "@prmf/site-router";
import { matchPath, useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";

type Props = { path?: string; items: string[] };

const MenuImage: React.FC<Props> = ({ path, items }) => {
  const { pathname } = useLocation();
  const initialPath = String(
    items.find((i) => matchPath(i, pathname)) || items[0],
  );
  const [image, setImage] = useState(t(initialPath, "image"));
  const [prev, setPrev] = useState(image);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (!path) return;
    const src = t(path, "image");
    if (!src) return;
    const img = new Image(0, 0);
    setImageLoading(true);
    let int = 0;
    img.onload = () => {
      int = window.setTimeout(() => {
        setImage(src), 300;
        setImageLoading(false);
      }, 400);
    };
    img.src = src;
    return () => clearTimeout(int);
  }, [path, prev]);

  return (
    <>
      <div
        key="prev"
        className="absolute inset-0 bg-neutral-100 bg-cover bg-center"
        style={prev ? { backgroundImage: `url(${prev})` } : {}}
      />
      <Transition
        key="next"
        show={!imageLoading}
        enter="transition ease-in"
        leave="transition ease-out"
        enterFrom="opacity-0 translate-x-2"
        leaveTo="opacity-0"
        className="absolute inset-0 bg-neutral-100 bg-cover bg-center"
        beforeLeave={() => setPrev(image)}
        style={image ? { backgroundImage: `url(${image})` } : {}}
      />
    </>
  );
};

export default MenuImage;
